import React from 'react';

interface IDotIconProps {
  className?: string;
}

const DotIcon = (props: IDotIconProps) => {
  const { className } = props;

  return (
    <svg
      className={className}
      width="12"
      height="12"
      viewBox="0 0 12 12"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="6"
        cy="1258"
        r="6"
        transform="translate(0 -1252)"
        fill="#4468FF"
        fillRule="evenodd"
      />
    </svg>
  );
};

export default React.memo(DotIcon);
