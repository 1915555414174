import React from 'react';

import BigBanner from '../components/Banners/BigBanner';
import { Layout } from '../components/common';
import { MetaData } from '../components/common/meta';
import { ContentSection } from '../components/ContentSection/ContentSection';
import EarnMore from '../components/EarnMore/EarnMore';
import DotImage from '../images/About/dot';
import illustrationImage from '../images/About/illustration.svg';

import style from '../styles/pages/about.scss';

interface IAboutProps {
  data: {
    allGhostPost: any;
  };
  location: {
    pathname: string;
  };
}

const About = ({ data, location }: IAboutProps) => {
  return (
    <>
      <MetaData location={location} data={{}} />
      <Layout isHome isWhiteHeader>
        <section className={style.hero}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h1>
                  We run a beautiful and <br className="d-none d-md-block" /> efficient marketplace.
                  <small>
                    Lead Economy enables top affiliates to maximize their profits by{' '}
                    <br className="d-none d-md-block" />
                    matching them with the highest paying advertisers.
                  </small>
                </h1>
                <img src={illustrationImage} alt="Illustration LeadEconomy" />
                <h2>
                  Rome wasn't built <br className="d-none d-sm-block" /> in a day
                </h2>
              </div>
            </div>
          </div>
        </section>

        <section className={style.heading}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12 col-lg-6">
                <p>
                  Regardless of the term used to describe the lead generation industry, the number
                  of functional elements needed to produce lead gen success means every facet, and
                  every market participant — has a role to play.
                </p>
                <p>
                  As the first affiliate enterprise of its kind, Lead Economy brings all the
                  essential elements of the lead gen supply chain together to provide an integrated,
                  lucrative marketplace where affiliates and advertisers can collaborate and achieve
                  superior results.
                </p>
                <p>
                  Whether you want to buy leads, generate traffic or monetize your data, there is a
                  place for you in the Lead Economy.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className={style.timeline}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <ContentSection className={style.yearInfo} inViewAnimationClass={style.isInView}>
                  <div>
                    <h2>2010-2013</h2>
                    <p>
                      Lead Economy was born through the countless hours and labor of the early
                      founders who had a vision to create a more efficient lead marketplace. A
                      healthy economy facilitates transactional relationships so that everyone can
                      play and win. Lead Economy was born to provide such an infrastructure in the
                      lead generation vertical.
                    </p>
                  </div>
                  <DotImage className={style.dot} />
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                </ContentSection>
                <ContentSection className={style.yearInfo} inViewAnimationClass={style.isInView}>
                  <div>
                    <h2>2014</h2>
                    <p>
                      In early 2014, Lead Economy's exclusive affiliate program and robust tracking
                      platform was introduced. LeadPier provided a robust tech infrastructure
                      enabling efficient transactions between affiliates and lenders.
                    </p>
                  </div>
                  <DotImage className={style.dot} />
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                </ContentSection>
                <ContentSection className={style.yearInfo} inViewAnimationClass={style.isInView}>
                  <div>
                    <h2>2015</h2>
                    <p>
                      2015 was a year of significant progress when new direct lenders were being
                      added to the Lead Economy network. At the same time, Trend Capital was
                      established as a holdings company for our family of technologies.
                    </p>
                  </div>
                  <DotImage className={style.dot} />
                  <div className={style.line}></div>
                  <div className={style.line}></div>
                </ContentSection>
                <ContentSection className={style.yearInfo} inViewAnimationClass={style.isInView}>
                  <div>
                    <h2>2016</h2>
                    <p>
                      Building off the growth of 2015, Lead Economy conquered email marketing.
                      Strong revenue streams were coming in and the team began to expand and
                      launched an exit path solution called RevPie. This new technology gave lenders
                      and publishers the chance to take advantage of their declined leads.
                    </p>
                  </div>
                  <DotImage className={style.dot} />
                  <div className={style.line}></div>
                </ContentSection>
                <ContentSection className={style.yearInfo} inViewAnimationClass={style.isInView}>
                  <div>
                    <h2>2017-20</h2>
                    <p>
                      These were big years for Lead Economy as the company continued to expand
                      internal teams. The affiliate program was also blossoming and growth was so
                      good that the company moved out of the second office space into a beautiful
                      waterfront facility occupying the entire 3rd floor. Now, with nearly 50
                      employees and growing, there is tremendous excitement for what the future
                      holds.
                    </p>
                  </div>
                  <DotImage className={style.dot} />
                </ContentSection>
              </div>
            </div>
          </div>
        </section>

        <section className={style.excitedChapter}>
          <div className="container">
            <div className="d-flex flex-wrap">
              <div className="col-12">
                <h2>
                  Why we're excited <br /> for the next chapter.
                </h2>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p className={style.bigLetter}>
                      Already positioned as a leader in the financial verticals, Lead Economy is
                      launching exciting new products lines and diversified verticals with an open
                      hand approach. Hand in hand, the Lead Economy team and its partners will break
                      ground on the new frontier.
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <p>
                      With the right team in place, the new CEO, Arkadiy, is the right person to
                      drive the enterprise forward. As a visionary and architect of multiple
                      successful business ventures, he brings a tech-first approach. This allows
                      Lead Economy to further stand out from the industry’s every day networks by
                      giving its business partners the opportunity to capitalize on today’s roaring
                      20’s.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <EarnMore isHiddenTitle />
        <BigBanner />
      </Layout>
    </>
  );
};

export default About;
